@charset "UTF-8";
.rbc-btn {
    color: inherit;
    font: inherit;
    margin: 0;
}

button.rbc-btn {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled].rbc-btn {
    cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.rbc-calendar {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
    box-sizing: inherit;
}

.rbc-abs-full,
.rbc-row-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rbc-rtl {
    direction: rtl;
}

.rbc-off-range {
    color: #999999;
}

.rbc-off-range-bg {
    background: #e6e6e6;
}

.rbc-header {
    overflow: hidden;
    flex: 1 0 0%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 3px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 90%;
    min-height: 0;
    border-bottom: 1px solid #286450;
    color: #286450;
}
.rbc-header + .rbc-header {
    border-left: 1px solid #286450;
}
.rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid #286450;
}
.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
    color: inherit;
    text-decoration: none;
}

.rbc-row-content {
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    z-index: 4;
}

.rbc-today {
    background-color: #bec8ea;
}

.rbc-toolbar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
    font-size: 16px;
}

.rbc-toolbar .rbc-toolbar-label {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    z-index: 1;
    padding: 0 10px;
    font-size: 2vw;
    text-align: center;
    color: #286450;
}

.rbc-toolbar button {
    color: #286450;
    display: inline-block;
    margin: 0;
    text-align: center;
    font-size: 0.8vw;
    vertical-align: middle;
    background: none;
    padding: 0.375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
    background-image: none;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
    color: #286450;
}

.rbc-btn-group {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    display: flex;
    justify-content: space-around;
    white-space: nowrap;
}

.rbc-btn-group button:first-child {
    display: none;
}

.rbc-btn-group button:nth-child(2) {
    width: 2vw;
    height: 2vw;
    font-size: 0;
    background: url(../../img/boxLeft.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
}

.rbc-btn-group button:last-child {
    width: 2vw;
    height: 2vw;
    font-size: 0;
    background: url(../../img/boxRight.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
}

.rbc-btn-group button + button {
    margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
    margin-left: 0;
    margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
    margin-left: 10px;
}

.rbc-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    border-radius: 5px;
    color: #286450;
    cursor: pointer;
    width: 100%;
    text-align: left;
}
.rbc-slot-selecting .rbc-event {
    cursor: inherit;
    pointer-events: none;
}

.rbc-event-label {
    font-size: 80%;
}

.rbc-event-overlaps {
    box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.rbc-event-continues-later {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-row {
    display: flex;
    flex-direction: row;
}

.rbc-row-segment {
    font-size: 0.8vw;
    padding: 0 1px 1px 1px;
}

.rbc-selected-cell {
    background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 4;
    font-weight: bold;
    font-size: 85%;
    height: auto;
    line-height: normal;
}

.rbc-month-view {
    position: relative;
    border: 1px solid #286450;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    height: 100%;
}

.rbc-month-header {
    display: flex;
    flex-direction: row;
}

.rbc-month-row {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 0 0;
    flex-basis: 0px;
    overflow: hidden;
    height: 100%;
}
.rbc-month-row + .rbc-month-row {
    border-top: 1px solid #286450;
}

.rbc-date-cell {
    flex: 1 1 0;
    min-width: 0;
    padding-right: 5px;
    text-align: right;
}
.rbc-date-cell.rbc-now {
    font-weight: bold;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
    color: #286450;
    text-decoration: none;
}

.rbc-row-bg {
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    overflow: hidden;
}

.rbc-day-bg {
    flex: 1 0 0%;
}
.rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #286450;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #286450;
}

.rbc-overlay {
    position: absolute;
    z-index: 5;
    border: 1px solid #286450;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    padding: 10px;
}
.rbc-overlay > * + * {
    margin-top: 1px;
}

.rbc-overlay-header {
    border-bottom: 1px solid #286450;
    margin: -10px -10px 5px -10px;
    padding: 2px 10px;
}

.rbc-label {
    padding: 0 5px;
}
