.SlideWrapper {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 665;
}

.SlideWrapper--close {
    animation: undim 0.4s ease-out forwards;
}

.SlideWrapper--open {
    animation: dim 0.3s ease-out forwards;
}

.SlideModal {
    transition: width 0.3s ease-out;
}

.h-textRight {
    text-align: left;
}

.h-overflowHidden {
    overflow: hidden !important;
}

.h-overflowAuto {
    overflow: auto !important;
}

.h-displayFlex {
    display: flex !important;
}

.h-flexCol {
    flex-direction: column !important;
}

.h-flexSpaceBetween {
    justify-content: space-between !important;
}

.SlideModal {
    position: fixed;
    /*right: -50%;*/
    top: 0;
    bottom: 0;
    width: 80%;
    background-color: #286450;
    z-index: 666;
}

.SlideModal--right {
    right: -50%;
}

.SlideModal--left {
    left: -50%;
}

.SlideModal--open.SlideModal--right {
    animation: slideIn--right 0.3s ease-out forwards;
}

.SlideModal--open.SlideModal--left {
    animation: slideIn--left 0.3s ease-out forwards;
}

.SlideModal--close.SlideModal--right {
    animation: slideOut--right 0.3s ease-out forwards;
}

.SlideModal--close.SlideModal--left {
    animation: slideOut--left 0.3s ease-out forwards;
}

.SlideModal__header {
    height: 10%;
    background-color: rgba(255, 255, 255);;
    z-index: 667;
}

.SlideModal__footer {}

.SlideModal__title {}

@keyframes dim {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

@keyframes undim {
    from {
        background-color: rgba(0, 0, 0, 0.5);
    }
    to {
        background-color: transparent;
    }
}

@keyframes slideIn--right {
    0% {
        right: calc(-100%);
    }
    100% {
        right: 0;
    }
}

@keyframes slideOut--right {
    0% {
        right: 0;
    }
    100% {
        right: calc(-100%);
    }
}

@keyframes slideIn--left {
    0% {
        left: calc(-100%);
    }
    100% {
        left: 0;
    }
}

@keyframes slideOut--left {
    0% {
        left: 0;
    }
    100% {
        left: calc(-100%);
    }
}
